import {
  Box,
  Heading,
  TextInput,
  Select,
  Button,
  Text,
  Form,
  FormField,
  Layer,
  Notification,
  Card,
  Data,
  DataSearch,
  Cards,
} from "grommet";

import React, { useEffect, useState } from "react";
import {
  fdmsExamGet,
  ListFDMSTestersByWindow,
  ListFDMSWindows,
} from "../graphql/queries";
import { API } from "aws-amplify";
//const axios = require("axios").default;
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { UpdateFDMSTester } from "../graphql/mutations";

dayjs.extend(utc);
dayjs.extend(timezone);

function FDMS() {
  const [windows, setWindows] = useState([]);
  const [selectedWindow, setSelectedWindow] = useState("");
  const [testers, setTesters] = useState([]);
  const [view, setView] = useState({ search: "$" });
  const [currInstructor, setCurrInstructor] = useState("");
  const [showInstructorPrompt, setShowInstructorPrompt] = useState(false);
  const [currentTester, setCurrentTester] = useState(null);
  const [isProctorCopy, setIsProctorCopy] = useState(false);
  const [isTesterCopy, setIsTesterCopy] = useState(false);

  useEffect(() => {
    getWindows();
  }, []);

  useEffect(() => {
    if (selectedWindow !== "") {
      getTesters();
    }
  }, [selectedWindow]);

  async function getWindows() {
    const data = await API.graphql({
      query: ListFDMSWindows,
    });
    console.log(data);
    let temp = data.data.listFDMSWindows.items.sort((a, b) => {
      return dayjs(a.id).isBefore(dayjs(b.id)) ? -1 : 1;
    });
    console.log(temp);
    setWindows(temp);
  }

  async function getTesters() {
    const data = await API.graphql({
      query: ListFDMSTestersByWindow,
      variables: {
        exam_window: selectedWindow,
      },
    });
    console.log(data);
    setTesters(data.data.fdmsByExamWindow.items);
  }

  function handleDataSearch(e) {
    if (e.target.value.length > 0) {
      setTimeout(() => {
        setView({ search: e.target.value });
      }, 1000);
    } else {
      setTimeout(() => {
        setView({ search: "$" });
      }, 1000);
    }
  }

  async function getLinksWithDeliveryId(tester) {
    console.log(tester);
    const data = await API.graphql({
      query: fdmsExamGet,
      variables: {
        first: tester.first_name,
        last: tester.last_name,
        email: tester.email,
        ins: tester.instructor,
        exam: tester.exam,
        action: "getExamWithDeliveryId",
        delivery: tester.delivery_id,
      },
    });

    console.log(data);
    const parsed = JSON.parse(data.data.fdmsExamGet);

    const updatedTester = await API.graphql({
      query: UpdateFDMSTester,
      variables: {
        input: {
          id: tester.id,
          delivery_id: parsed.delivery_id,
        },
      },
    });

    const newTester = {
      ...tester,
      delivery_id: parsed.delivery_id,
      take_link:
        "https://scorpion.caveon.com/take?launch_token=" + parsed.launch_token,
      proctor_link:
        "https://scorpion.caveon.com/proctor/" + parsed.proctor_token,
    };

    const newTesters = testers.map((t) => {
      if (t.id === tester.id) {
        return newTester;
      } else {
        return t;
      }
    });
    setTesters(newTesters);
  }

  async function getLinks(tester) {
    if (tester.instructor === null) {
      setShowInstructorPrompt(true);
      setCurrentTester(tester);
      return;
    }
    const data = await API.graphql({
      query: fdmsExamGet,
      variables: {
        first: tester.first_name,
        last: tester.last_name,
        email: tester.email,
        ins: tester.instructor,
        exam: tester.exam,
        action: "getInitialExam",
      },
    });

    console.log(data);
    const parsed = JSON.parse(data.data.fdmsExamGet);
    console.log(parsed);
    // update the tester with the delivery id
    const updatedTester = await API.graphql({
      query: UpdateFDMSTester,
      variables: {
        input: {
          id: tester.id,
          delivery_id: parsed.delivery_id,
          instructor: tester.instructor,
        },
      },
    });

    const newTester = {
      ...tester,
      delivery_id: parsed.delivery_id,
      take_link:
        "https://scorpion.caveon.com/take?launch_token=" + parsed.launch_token,
      proctor_link:
        "https://scorpion.caveon.com/proctor/" + parsed.proctor_token,
    };

    const newTesters = testers.map((t) => {
      if (t.id === tester.id) {
        return newTester;
      } else {
        return t;
      }
    });
    setTesters(newTesters);
  }

  return (
    <Box align="center" justify="center" margin={{ top: "small" }}>
      {showInstructorPrompt && (
        <Layer
          position="center"
          modal
          onClickOutside={() => setShowInstructorPrompt(false)}
        >
          <Box pad="medium" gap="small" width="medium">
            <Heading level={4} margin="none">
              Instructor Email Required
            </Heading>
            <Text>
              Please select the instructor email for the tester before
              continuing
            </Text>
            <Select
              options={[
                "Alison.owens@dms.fl.gov",
                "Ashley.sellars@dms.fl.gov",
                "Lisa.Eaton@dms.fl.gov",
                "Ricky.Lay@dms.fl.gov",
              ]}
              value={currInstructor}
              onChange={({ option }) => setCurrInstructor(option)}
              placeholder="Select Instructor"
            />
            <Button
              label="Submit"
              primary
              onClick={() => {
                const newTester = {
                  ...currentTester,
                  instructor: currInstructor,
                };
                setCurrInstructor("");
                setShowInstructorPrompt(false);
                getLinks(newTester);
              }}
            />
          </Box>
        </Layer>
      )}
      {isProctorCopy && (
        <Notification
          toast
          status="normal"
          title="Proctor link copied to clipboard"
          onClose={() => setIsProctorCopy(false)}
        />
      )}
      {isTesterCopy && (
        <Notification
          toast
          status="normal"
          title="Tester link copied to clipboard"
          onClose={() => setIsTesterCopy(false)}
        />
      )}
      <Card fill="horizontal" background="light-1" pad="medium">
        <Box align="center" justify="center" direction="row" gap="small">
          {windows.length > 0 && (
            <Box background="white" round="small">
              <Select
                options={windows.map((win) => win.id)}
                value={selectedWindow}
                onChange={({ option }) => setSelectedWindow(option)}
                placeholder="Select Exam Window"
              />
            </Box>
          )}
        </Box>
      </Card>
      <Box
        align="center"
        justify="center"
        margin={{ top: "small" }}
        width="full"
      >
        {testers.length > 0 && (
          <Data
            data={testers}
            view={view}
            defaultView={{ search: "$" }}
            width="full"
          >
            <TextInput
              placeholder="Search"
              onChange={handleDataSearch}
              width="medium"
            />
            <Cards size="full">
              {(tester) => (
                <Card
                  key={tester.id}
                  background="light-1"
                  margin="small"
                  pad="small"
                  round="small"
                  width="full"
                >
                  <Box direction="row" gap="small" align="center">
                    <Text weight="bold">{tester.exam}</Text>
                    <Text weight="bold">
                      {tester.first_name} {tester.last_name}
                    </Text>
                    <Text>{tester.email}</Text>
                    <Text>{tester.instructor}</Text>

                    {tester.delivery_id ? (
                      <Button
                        label="Get Links"
                        primary
                        onClick={() => getLinksWithDeliveryId(tester)}
                      />
                    ) : (
                      <Button
                        label="Get Links"
                        primary
                        onClick={() => getLinks(tester)}
                      />
                    )}
                    {tester.take_link && (
                      <Button
                        label="Copy Tester Link"
                        onClick={() => {
                          navigator.clipboard.writeText(tester.take_link);
                          setIsTesterCopy(true);
                        }}
                      />
                    )}
                    {tester.proctor_link && (
                      <Button
                        label="Copy Proctor Link"
                        onClick={() => {
                          navigator.clipboard.writeText(tester.proctor_link);
                          setIsProctorCopy(true);
                        }}
                      />
                    )}
                  </Box>
                </Card>
              )}
            </Cards>
          </Data>
        )}
      </Box>
    </Box>
  );
}

/*
function FDMS() {
  const [proctor, setProctor] = useState("");
  const [exam, setExam] = useState("");
  const [isExam, setIsExam] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [value, setValue] = useState({
    first: "",
    last: "",
    email: "",
    ins: "",
    examName: "",
  });
  const [showProc, setShowProc] = useState(false);
  const [showExam, setShowExam] = useState(false);
  const [notify, setNotify] = useState(false);
  const [link, setLink] = useState("");
  const examOptions = [
    "Florida Certified Contract Manager (FCCM) Certification Exam",
    "Florida Certified Contract Negotiator (FCCN) Certification Exam",
  ];
  async function handleSubmit() {
    console.log(value);

    const data = await API.graphql({
      query: fdmsExamGet,
      variables: {
        first: value.first,
        last: value.last,
        email: value.email,
        ins: value.ins,
        exam: value.examName,
      },
    });
    const par = JSON.parse(data.data.fdmsExamGet);
    console.log(par);

    setProctor(par.proctor_token);
    setExam(par.launch_token);
    setIsConfirm(false);
    setCurrentValue(value);
    setValue({ first: "", last: "", email: "", ins: "", examName: "" });
    setIsExam(true);
  }

  const handleCopy = (val, type) => {
    setLink(type);
    navigator.clipboard.writeText(val);
    setNotify(true);
  };

  return (
    <Box align="center" justify="center">
      {isConfirm && (
        <Layer
          position="center"
          modal
          onClickOutside={() => setIsConfirm(false)}
        >
          <Box pad="medium" gap="small" width="medium">
            <Heading level={4} margin="none">
              CONFIRM THIS INFO IS CORRECT
            </Heading>
            <Text>First Name: {value.first}</Text>
            <Text>Last Name: {value.last}</Text>
            <Text>Email: {value.email}</Text>
            <Text>Instructor: {value.ins}</Text>
            <Text>Exam: {value.examName}</Text>
            <Button label="Confirm" primary onClick={() => handleSubmit()} />
            <Button label="Cancel" onClick={() => setIsConfirm(false)} />
          </Box>
        </Layer>
      )}

      {notify && (
        <Notification
          toast
          status="normal"
          title={link + " link copied to clipboard"}
          onClose={() => setNotify(false)}
          time={1500}
        />
      )}
      <Heading>FDMS Links</Heading>
      <Box
        direction="row"
        gap="medium"
        justify="center"
        align="start"
        pad="xsmall"
      >
        <Box align="center" justify="center" gap="small" width="large">
          <Form
            value={value}
            onChange={(nextValue) => {
              console.log(nextValue);
              setValue(nextValue);
            }}
            onSubmit={() => setIsConfirm(true)}
            style={{ width: "100%" }}
            infos={{ examName: "CHECK TAWK PROPERTY FOR EXAM NAME" }}
          >
            <FormField label="Tester First Name" name="first" required={true}>
              <TextInput placeholder="Tester First Name" name="first" />
            </FormField>
            <FormField label="Tester Last Name" name="last" required={true}>
              <TextInput placeholder="Tester Last Name" name="last" />
            </FormField>
            <FormField
              label="Tester State Issued Email"
              name="email"
              required={true}
            >
              <TextInput
                placeholder="Tester State Issued Email"
                name="email"
                type="email"
              />
            </FormField>
            <FormField label="Instructor" name="ins" required={true}>
              <Select
                placeholder="Instructor"
                options={[
                  "Alison.owens@dms.fl.gov",
                  "Ashley.sellars@dms.fl.gov",
                  "Lisa.Eaton@dms.fl.gov",
                  "Ricky.Lay@dms.fl.gov",
                ]}
                name="ins"
              />
            </FormField>
            <FormField label="Exam" name="examName" required={true}>
              <Select
                placeholder="Exam Name"
                options={examOptions}
                name="examName"
              />
            </FormField>
            <Button label="Submit" type="submit" />
          </Form>
        </Box>
        {isExam && (
          <Box
            align="start"
            justify="start"
            gap="small"
            margin={{ left: "medium" }}
          >
            <hr />
            <br />
            <Text>Links For:</Text>
            <Text>
              {currentValue.first} {currentValue.last}
            </Text>
            <Text> {currentValue.email}</Text>
            <Text> {currentValue.ins}</Text>
            <Text> {currentValue.examName}</Text>
            <Box direction="row" gap="small">
              <Button
                primary
                label="Copy Proctor Link"
                onClick={() =>
                  handleCopy(
                    `https://scorpion.caveon.com/proctor/${proctor}`,
                    "proctor"
                  )
                }
              />
              <Button
                label={showProc ? "Hide Proctor Link" : "Show Proctor Link"}
                onClick={() => setShowProc(!showProc)}
              />
            </Box>
            {showProc && (
              <Text>
                Proctor Link: https://scorpion.caveon.com/proctor/{proctor}
              </Text>
            )}
            <Box direction="row" gap="small">
              <Button
                primary
                label="Copy Examinee Link"
                onClick={() =>
                  handleCopy(
                    `Exam Link for ${currentValue.first} ${currentValue.last} - ${currentValue.examName} https://scorpion.caveon.com/take?launch_token=${exam}`,
                    "tester"
                  )
                }
              />
              <Button
                label={showExam ? "Hide Examinee Link" : "Show Examinee Link"}
                onClick={() => setShowExam(!showExam)}
              />
            </Box>
            {showExam && (
              <Text>
                Examinee Link: https://scorpion.caveon.com/take?launch_token=
                {exam}
              </Text>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

*/
export default FDMS;
