/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const invigilexamgetter = /* GraphQL */ `
  query Invigilexamgetter(
    $examId: String
    $proctorId: String
    $custId: String
  ) {
    invigilexamgetter(examId: $examId, proctorId: $proctorId, custId: $custId)
  }
`;
export const getWebcamLink = /* GraphQL */ `
  query GetWebcamLink($id: ID!) {
    getWebcamLink(id: $id) {
      id
      embedid
      roomid
      proctor
      tester
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const listWebcamLinks = /* GraphQL */ `
  query ListWebcamLinks(
    $filter: ModelWebcamLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebcamLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        embedid
        roomid
        proctor
        tester
        status
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWebcamRoom = /* GraphQL */ `
  query GetWebcamRoom($id: ID!) {
    getWebcamRoom(id: $id) {
      id
      name
      embedCode
      ScCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const listWebcamRooms = /* GraphQL */ `
  query ListWebcamRooms(
    $filter: ModelWebcamRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebcamRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        embedCode
        ScCode
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvigilExam = /* GraphQL */ `
  query GetInvigilExam($id: ID!) {
    getInvigilExam(id: $id) {
      id
      examId
      title
      materials
      instructor
      email
      quantity
      instructions
      accom
      date
      format
      createdAt
      type
      custId
      updatedAt
    }
  }
`;
export const listInvigilExams = /* GraphQL */ `
  query ListInvigilExams(
    $filter: ModelInvigilExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvigilExams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        examId
        title
        materials
        instructor
        email
        quantity
        instructions
        accom
        date
        format
        createdAt
        type
        custId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHpeExam = /* GraphQL */ `
  query GetHpeExam($id: ID!) {
    getHpeExam(id: $id) {
      id
      candidateToken
      proctorToken
      examName
      examStart
      examDuration
      name
      createdAt
      updatedAt
    }
  }
`;
export const listHpeExams = /* GraphQL */ `
  query ListHpeExams(
    $filter: ModelHpeExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHpeExams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        candidateToken
        proctorToken
        examName
        examStart
        examDuration
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLsoTester = /* GraphQL */ `
  query GetLsoTester($id: String!) {
    getLsoTester(id: $id) {
      id
      email
      fName
      lName
    }
  }
`;
export const listLsoTesters = /* GraphQL */ `
  query ListLsoTesters(
    $filter: ModelLsoTesterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLsoTesters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        fName
        lName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const webcamLinkByDate = /* GraphQL */ `
  query WebcamLinkByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebcamLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webcamLinkByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        embedid
        roomid
        proctor
        tester
        status
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const webcamRoomByDate = /* GraphQL */ `
  query WebcamRoomByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebcamRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    WebcamRoomByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        embedCode
        ScCode
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invigilExamByDate = /* GraphQL */ `
  query InvigilExamByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvigilExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InvigilExamByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        examId
        title
        materials
        instructor
        email
        quantity
        instructions
        accom
        date
        format
        createdAt
        type
        custId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      name
      embedCode
      proctorCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        embedCode
        proctorCode
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roomByDate = /* GraphQL */ `
  query RoomByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RoomByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        embedCode
        proctorCode
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getCrpoTester = /* GraphQL */ `
  query GetCrpoTester($email: String!) {
    getCrpoTester(email: $email) {
      email
      acct
      fName
      lName
      password
      code
      login
    }
  }
`;

export const getCcahmTester = /* GraphQL */ `
  query GetCcahmTester($id: String!) {
    getCcahmTester(id: $id) {
      email
      examCode
      fName
      id
      lName
    }
  }
`;

export const GetEpecTester = /* GraphQL */ `
  query getEpecTester($id: String!) {
    getEpecTester(id: $id) {
      codeOne
      codeTwo
      codeThree
      fName
      lName
      group
      id
    }
  }
`;

export const ListEpecTesters = /* GraphQL */ `
  query ListEpecTesters(
    $filter: ModelEpecTesterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEpecTesters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        codeOne
        codeTwo
        codeThree
        fName
        lName
        group
        id
      }
    }
  }
`;

export const GetMartekTester = /* GraphQL */ `
  query getMartekTester($id: String!) {
    getMartekTester(id: $id) {
      codeOne
      codeTwo
      fName
      lName
      id
      email
    }
  }
`;

export const ListMartekTesters = /* GraphQL */ `
  query ListMartekTesters(
    $filter: ModelMartekTesterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMartekTesters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        codeOne
        codeTwo
        fName
        lName
        id
        email
      }
    }
  }
`;

export const martekByEmail = /* GraphQL */ `
  query martekByEmail($email: String!) {
    martekByEmail(email: $email) {
      items {
        codeOne
        codeTwo
        fName
        lName
        id
      }
    }
  }
`;

export const getPaidTesterByRef = /* GraphQL */ `
  query getPaidTesterByRef($referenceId: String!) {
    testerByReferenceId(referenceId: $referenceId) {
      items {
        createdAt
        cust_email
        cust_name
        item_name
        item_quantity
        lastFour
        verified_by
        referenceId
        paymentId
        verified_at
      }
    }
  }
`;
export const getPaidTesterByEmail = /* GraphQL */ `
  query getPaidTesterByEmail($cust_email: String!) {
    testerByEmail(cust_email: $cust_email) {
      items {
        createdAt
        cust_email
        cust_name
        item_name
        item_quantity
        lastFour
        verified_by
        referenceId
        paymentId
        verified_at
      }
    }
  }
`;

export const getPaidTesterByLastFour = /* GraphQL */ `
  query getPaidTesterByLastFour($lastFour: String!) {
    testerByLastFour(lastFour: $lastFour) {
      items {
        createdAt
        cust_email
        cust_name
        item_name
        item_quantity
        lastFour
        verified_by
        referenceId
        paymentId
        verified_at
      }
    }
  }
`;

export const listPaidTesters = /* GraphQL */ `
  query listPaidTesters(
    $filter: ModelPaidTesterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaidTesters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        cust_email
        cust_name
        item_name
        item_quantity
        lastFour
        verified_by
        referenceId
        paymentId
        verified_at
      }
    }
  }
`;

export const getDbtTester = /* GraphQL */ `
  query getDbtTester($id: String!) {
    getDBTTester(id: $id) {
      id
      name
      email
      exam
    }
  }
`;

export const DbtByEmail = /* GraphQL */ `
  query DbtByEmail($email: String!) {
    dbtByEmail(email: $email) {
      items {
        appointment
        deliveryId
        email
        id
        name
        exam
      }
    }
  }
`;

export const listDBTTesters = /* GraphQL */ `
  query listDBTTesters(
    $filter: ModelDBTTesterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDBTTesters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        appointment
        deliveryId
        email
        id
        name
        exam
      }
      nextToken
    }
  }
`;

export const dbtExamPost = /* GraphQL */ `
  query dbtExamPost($email: String, $id: String, $name: String, $exam: String) {
    dbtExamPost(email: $email, id: $id, name: $name, exam: $exam)
  }
`;

export const fdmsExamGet = /* GraphQL */ `
  query fdmsExamGet(
    $first: String
    $last: String
    $email: String
    $ins: String
    $exam: String
    $action: String
    $delivery: String
  ) {
    fdmsExamGet(
      first: $first
      last: $last
      email: $email
      ins: $ins
      exam: $exam
      action: $action
      delivery: $delivery
    )
  }
`;

export const dbtExamGet = /* GraphQL */ `
  query dbtExamGet($id: String) {
    dbtExamGet(id: $id)
  }
`;

export const cmsExamGet = /* GraphQL */ `
  query cmsExamGet($exam_id: String, $delivery: String) {
    cmsExamGet(exam_id: $exam_id, delivery: $delivery)
  }
`;

export const getCmsCandidate = /* GraphQL */ `
  query getCmsCandidate($user_id: String!) {
    getCmsCandidate(user_id: $user_id) {
      email_address
      first_name
      last_name
      candidate_id
    }
  }
`;

export const getCmsScheduleDetails = /* GraphQL */ `
  query getCmsScheduleDetails(
    $user_id: String!
    $exam_id: String!
    $registration_id: String!
  ) {
    getCmsCandidate(user_id: $user_id) {
      email_address
      first_name
      last_name
      candidate_id
    }
    getCmsExam(exam_id: $exam_id) {
      name
      utc_start_date
      utc_end_date
    }
    getCmsRegistration(registration_id: $registration_id) {
      active
      utc_schedule_by
      appointment {
        local_date
        exam_date
      }
    }
  }
`;

export const getCmsNonce = /* GraphQL */ `
  query getCmsNonce($nonce_id: String!) {
    getCmsNonce(nonce_id: $nonce_id) {
      nonce_id
      user_id
      expired
    }
  }
`;

export const getCmsSessionByRegistrationId = /* GraphQL */ `
  query sessionByRegistrationId($registration_id: String!) {
    sessionByRegistrationId(registration_id: $registration_id) {
      items {
        session_id
        registration_id
      }
    }
  }
`;

export const getCmsSessionByProgress = /* GraphQL */ `
  query sessionByProgress($progress: String!, $limit: Int, $nextToken: String) {
    sessionByProgress(
      progress: $progress
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        session_id
        registration_id
        exam_id
        user_id
        progress
        first_name
        last_name
        email_address
        candidate_id
      }
      nextToken
    }
  }
`;

export const getCmsSessionDetails = /* GraphQL */ `
  query getCmsSessionDetails(
    $session_id: String!
    $registration_id: String!
    $exam_id: String!
  ) {
    getCmsSession(session_id: $session_id) {
      email_address
      end_time
      first_name
      last_name
      notes {
        note
        severity
      }
      proctor_id
      progress
      start_time
      registration_id
      exam_id
      candidate_id
    }
    getCmsRegistration(registration_id: $registration_id) {
      sei_delivery_id
      special_instructions {
        name
        value
      }
    }
    getCmsExam(exam_id: $exam_id) {
      name
      exam_url
      exam_type
      sei_exam_id
      proctor_instructions
      candidate_instructions
      language_code
    }
  }
`;

export const getClient = /* GraphQL */ `
  query getClient($id: String!) {
    getClient(id: $id) {
      id
      name
      note_format
      pre_chat_form
      tawk_property_link
    }
  }
`;

export const getClientPub = /* GraphQL */ `
  query getClient($id: String!) {
    getClient(id: $id) {
      id
      name
      pre_chat_form
      tawk_property_link
    }
  }
`;

export const listSessionsByStatus = /* GraphQL */ `
  query listSessionsByStatus(
    $status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    sessionByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
    ) {
      items {
        id
        first_name
        last_name
        client_name
        status
        proctor_history
      }
    }
  }
`;

export const sessionByProctor = /* GraphQL */ `
  query sessionByProctor(
    $proctor: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    sessionByProctor(
      proctor: $proctor
      createdAt: $createdAt
      sortDirection: $sortDirection
    ) {
      items {
        appointment
        candidate_id
        client_id
        client_name
        email
        end_time
        exam
        exam_password
        first_name
        flag
        google_meet_link
        id
        instructor
        last_name
        lesson_id
        notes
        pass
        phone
        proctor
        proctor_history
        receipt_number
        school
        score
        start_time
        status
      }
    }
  }
`;

export const sessionByStatus = /* GraphQL */ `
  query sessionsByStatus(
    $status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    sessionByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
    ) {
      items {
        appointment
        candidate_id
        client_id
        client_name
        createdAt
        email
        end_time
        exam
        exam_password
        first_name
        flag
        google_meet_link
        id
        instructor
        last_name
        lesson_id
        notes
        pass
        phone
        proctor
        proctor_history
        receipt_number
        school
        score
        start_time
        status
      }
      nextToken
    }
  }
`;

export const sessionByFlag = /* GraphQL */ `
  query sessionsByFlag(
    $flag: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    sessionByFlag(
      flag: $flag
      createdAt: $createdAt
      sortDirection: $sortDirection
    ) {
      items {
        appointment
        candidate_id
        client_id
        client_name
        createdAt
        email
        end_time
        exam
        exam_password
        first_name
        flag
        google_meet_link
        id
        instructor
        last_name
        lesson_id
        notes
        pass
        phone
        proctor
        proctor_history
        receipt_number
        school
        score
        start_time
        status
      }
      nextToken
    }
  }
`;

export const sessionByClient = /* GraphQL */ `
  query sessionsByClient(
    $client_id: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    sessionByClientId(
      client_id: $client_id
      createdAt: $createdAt
      sortDirection: $sortDirection
    ) {
      items {
        appointment
        candidate_id
        client_id
        client_name
        createdAt
        email
        end_time
        exam
        exam_password
        first_name
        flag
        google_meet_link
        id
        instructor
        last_name
        lesson_id
        notes
        pass
        phone
        proctor
        proctor_history
        receipt_number
        school
        score
        start_time
        status
      }
      nextToken
    }
  }
`;

export const listSessions = /* GraphQL */ `
  query listSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        appointment
        candidate_id
        client_id
        client_name
        createdAt
        email
        end_time
        exam
        exam_password
        first_name
        flag
        google_meet_link
        id
        instructor
        last_name
        lesson_id
        notes
        pass
        phone
        proctor
        proctor_history
        receipt_number
        school
        score
        start_time
        status
      }
      nextToken
    }
  }
`;

export const GetMySessionsAndQueue = /* GraphQL */ `
  query GetMySessionsAndQueue(
    $proctor: String!
    $status: String!
    $filter: ModelSessionFilterInput
  ) {
    sessionByProctor(filter: $filter, proctor: $proctor) {
      items {
        appointment
        candidate_id
        client_id
        client_name
        createdAt
        email
        end_time
        exam
        exam_password
        first_name
        flag
        google_meet_link
        id
        instructor
        last_name
        lesson_id
        notes
        pass
        phone
        proctor
        proctor_history
        receipt_number
        school
        score
        start_time
        status
      }
    }
    sessionByStatus(status: $status) {
      items {
        id
        first_name
        last_name
        client_name
        client_id
        proctor_history
      }
    }
  }
`;

export const listExemplarTesters = /* GraphQL */ `
  query listExemplarTesters(
    $filter: ModelExemplarTesterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExemplarTesters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        first_name
        id
        last_name
        proctor_code
        test_code
      }
    }
  }
`;

export const sessionByType = /* GraphQL */ `
  query sessionByType(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelSessionFilterInput
    $sortDirection: ModelSortDirection
  ) {
    sessionByType(
      type: $type
      createdAt: $createdAt
      filter: $filter
      sortDirection: $sortDirection
    ) {
      items {
        appointment
        candidate_id
        client_id
        client_name
        createdAt
        email
        end_time
        exam
        exam_password
        first_name
        flag
        google_meet_link
        id
        instructor
        last_name
        lesson_id
        notes
        pass
        phone
        proctor
        proctor_history
        receipt_number
        school
        score
        start_time
        status
      }
      nextToken
    }
  }
`;

export const sessionFinisherFunction = /* GraphQL */ `
  query sessionFinisherFunction($session: String) {
    sessionFinisherFunction(session: $session)
  }
`;

export const getTwilioAccessToken = /* GraphQL */ `
  query getTwilioAccessToken($body: String!) {
    getTwilioAccessToken(body: $body)
  }
`;

export const getGoogleRoomDetails = /* GraphQL */ `
  query GetGoogleRoomDetails($action: String!, $payload: String!) {
    getGoogleRoomDetails(action: $action, payload: $payload)
  }
`;

export const getSquareDetails = /* GraphQL */ `
  query GetSquareDetails($action: String!, $payload: String!) {
    getSquareDetails(action: $action, payload: $payload)
  }
`;

export const getTwilioByType = /* GraphQL */ `
  query roomByType(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTwilioSCRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        proctor
        status
        tester
      }
    }
  }
`;

export const listExamCodeTables = /* GraphQL */ `
  query listExamCodeTables(
    $filter: ModelExamCodeTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExamCodeTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client_name
        exam_name
        code_bucket_key
      }
    }
  }
`;

export const getProlydianRegistrationLite = /* GraphQL */ `
  query GetRegistrationLite($id: String!) {
    getProlydianRegistration(id: $id) {
      email
      firstName
      id
      lastName
    }
  }
`;

export const getAOAName = /* GraphQL */ `
  query GetAOAExamSetup($action: String!, $payload: String!) {
    getAOAExamSetup(action: $action, payload: $payload)
  }
`;

export const ListProlydianRegistrations = /* GraphQL */ `
  query ListProlydianRegistrations(
    $filter: ModelProlydianRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProlydianRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        appointment
        candidateId
        email
        examId
        examUrl
        firstName
        id
        lastName
        pauseStopUrl
        unlockCode
      }
      nextToken
    }
  }
`;

export const getProlydianExam = /* GraphQL */ `
  query GetProlydianExam($id: String!) {
    getProlydianExam(id: $id) {
      candidateInstructions
      examName
      proctorInstructions
      id
    }
  }
`;

export const listCraneGuageSessions = /* GraphQL */ `
  query listCraneGuageSessions(
    $filter: ModelCraneGuageSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCraneGuageSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        session_id
        user_name
        user_email
        test_name
        password
        exam_url
        proctor_instructions
        proctor
        start_time
        end_time
        flag
        notes
        google_meet_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTalGaugeSessions = /* GraphQL */ `
  query listTalGaugeSessions(
    $filter: ModelTalGaugeSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTalGaugeSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        session_id
        user_name
        user_email
        test_name
        password
        exam_url
        proctor_instructions
        proctor
        start_time
        end_time
        flag
        notes
        google_meet_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAAAFoodHandlerSessions = /* GraphQL */ `
  query listAAAFoodHandlerSessions(
    $filter: ModelAAAFoodHandlerSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAAAFoodHandlerSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        CourseLanguage
        EmailAddress
        ExamId
        Name
        SessionId
        StudentId
        Status
        ExamStatus
        createdAt
      }
      nextToken
    }
  }
`;

export const getInitialGoogleRoomData = /* GraphQL */ `
  query GetInitialGoogleRoomData {
    listClients {
      items {
        id
        name
      }
    }
    listUsers {
      items {
        email
        googleAccessToken
        googleRefreshToken
        googleTokenExpiry
        id
      }
    }
  }
`;

export const getGoogleRoomsByOrganizer = /* GraphQL */ `
  query GetGoogleRoomsByOrganizer(
    $organizer: String!
    $sortDirection: ModelSortDirection
    $nextToken: String
  ) {
    googleRoomByOrganizer(
      organizer: $organizer
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      items {
        candidateEmail
        candidateName
        clientId
        clientName
        createdAt
        createdAtDate
        googleAccessToken
        googleRefreshToken
        googleTokenExpiry
        id
        organizer
        mobileRoomCode
        mobileRoomLink
        mobileRoomName
        webcamRoomLink
        webcamRoomName
        webcamRoomCode
        type
      }
      nextToken
    }
  }
`;

export const getGoogleRoomsByCreatedAtDate = /* GraphQL */ `
  query GetGoogleRoomsByCreatedAtDate(
    $createdAtDate: String!
    $nextToken: String
  ) {
    googleRoomByDate(createdAtDate: $createdAtDate, nextToken: $nextToken) {
      items {
        candidateEmail
        candidateName
        clientId
        clientName
        createdAt
        createdAtDate
        googleAccessToken
        googleRefreshToken
        googleTokenExpiry
        id
        organizer
        mobileRoomCode
        mobileRoomLink
        mobileRoomName
        webcamRoomLink
        webcamRoomName
        webcamRoomCode
        type
      }
      nextToken
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      email
      googleAccessToken
      googleRefreshToken
      googleTokenExpiry
      id
    }
  }
`;

export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
    }
  }
`;

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      notes
      variations {
        name
        price
      }
    }
  }
`;

export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      createdAt
      createdAtDate
      id
      price
      payer_name
      payer_email
      order_id
      product_id
      product_name_and_option
      quantity
      status
      tester_email
      tester_name
      updatedAt
    }
  }
`;

export const HpeByExamDate = /* GraphQL */ `
  query HpeByExamDate($examDate: String!) {
    hpeByExamDate(examDate: $examDate) {
      items {
        id
        candidateToken
        proctorToken
        examName
        examStart
        examDate
        examDuration
        name
        createdAt
      }
    }
  }
`;

export const ListBackupQueue = /* GraphQL */ `
  query ListBackupQueue(
    $filter: ModelBackupQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBackupQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client
        candidate_name
        exam
        type
        createdAt
        meeting_link
        status
        proctor
      }
      nextToken
    }
  }
`;

export const BackupQueueByType = /* GraphQL */ `
  query BackupQueueByType(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBackupQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    backupQueueByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client
        candidate_name
        exam
        type
        createdAt
        meeting_link
        status
        proctor
      }
      nextToken
    }
  }
`;

export const BackupQueueByStatus = /* GraphQL */ `
  query BackupQueueByStatus(
    $status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBackupQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    backupQueueByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client
        candidate_name
        exam
        type
        createdAt
        meeting_link
        status
        proctor
      }
      nextToken
    }
  }
`;

export const ListDemoCaveonExams = /* GraphQL */ `
  query ListDemoCaveonExams(
    $filter: ModelDemoCaveonExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDemoCaveonExams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const ListFDMSWindows = /* GraphQL */ `
  query ListFDMSWindows(
    $filter: ModelFDMSWindowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFDMSWindows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
      }
      nextToken
    }
  }
`;

export const ListFDMSTestersByWindow = /* GraphQL */ `
  query fdmsByExamWindow($exam_window: String!) {
    fdmsByExamWindow(exam_window: $exam_window) {
      items {
        delivery_id
        createdAt
        email
        exam
        exam_window
        first_name
        id
        instructor
        last_name
        updatedAt
      }
    }
  }
`;
