import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormField,
  Heading,
  Select,
  Spinner,
  Text,
  TextInput,
} from "grommet";
import { CreateBackupQueue } from "../graphql/mutations";
import { API } from "aws-amplify";
import { onUpdateBackupQueue } from "../graphql/subscriptions";
import { useParams, useSearchParams } from "react-router-dom";
const clientOptions = [
  "Other, not listed",
  "360 Training",
  "AAID - In Person Exams",
  "AAID - Oral Exams",
  "AAID - Virtual Exams",
  "ABMDI",
  "ABN - Oral Exam",
  "ABN - Written Exam",
  "ABSA",
  "ABSA - In Person",
  "ABPMR Oral Exams",
  "Addiction Medicine Conjoint Examination (AOA)",
  "AF - Group",
  "Aiken County Public School District",
  "Alabama Alarm Association",
  "Allied Schools ",
  "Alpha School",
  "American Board of Preventive Medicine",
  "American Board of Surgery",
  "American Osteopathic Conjoint Pain Medicine - AOCPM",
  "American Society of Ocularists",
  "Andover eCademy",
  "AOA Written Exams (AOA Conjoint Examination Committees)",
  "AOBA - Oral Exam",
  "AOA Written Exams (AOBA)",
  "AOA Written Exams (AOBD)",
  "AOBEM - Oral Exam",
  "AOA Written Exams (AOBEM)",
  "AOA Written Exams (AOBFP)",
  "AOA Written Exams (DOEC)",
  "AOA Written Exams (AOBIM)",
  "AOBNMM - Oral Exam",
  "AOA Written Exams (AOBNMM)",
  "AOA Written Exams (AOBNP)",
  "AOBOG - Oral Exam",
  "AOA Written Exams (AOBOG)",
  "AOBOO - Oral Exam",
  "AOA Written Exams (AOBOO)",
  "AOA Written Exams (AOBOS)",
  "AOA Written Exams (AOBP)",
  "AOA Written Exams (AOBPM)",
  "AOBPMR - Oral Exam",
  "AOA Written Exams (AOBPMR)",
  "AOBR - Oral Exam",
  "AOA Written Exams (AOBR)",
  "AOBS - Oral Exam",
  "AOA Written Exams (AOBS)",
  "Arizona Collegiate HS/ Pima Prevention HS",
  "Arizona Preparatory Academy",
  "Arkansas Security Alarm Association",
  "ASC - American Chiropractic Board of Sports Physicians (ACBSP)",
  "ASC - CCAHM",
  "ASC - CCEB",
  "ASC - CCSF: Office of the Controller, Human Resources",
  "ASC - College of Chiropodists of Ontario (COCOO)",
  "ASC - College of Denturists of Ontario",
  "ASC - Denturists Multi-Jurisdictional Examination",
  "ASC - Diplomate in Safety Pharmacology (DSP)",
  "ASC - EndoANP",
  "ASC - Exemplar Global/iNARTE",
  "ASC - Florida Government Finance Officers Association (FGFOA)",
  "ASC - Gastroenterology Association of Naturopathic Physicians",
  "ASC - International Association of Emergency Managers",
  "ASC - International Board of Environmental Risk Assessors (IBERA)",
  "ASC - MEF Forum",
  "ASC - Michigan State University",
  "ASC - National Association of the Remodeling Industry",
  "ASC - NOAH",
  "ASC - North American Board of Breastfeeding and Lactation Medicine (NABBLM)",
  "ASC - OEBC",
  "ASC - OncANP",
  "ASC - Core Design (ABNO)",
  "ASC - PA DEP",
  "ASC - Philips",
  "ASC - Product School",
  "ASC - SF Gov",
  "ASC - USOLN",
  "ASC - Washington State Labor and Industries",
  "ASC - WPiAM",
  "ASC- IPMA - HR",
  "Atlantic Real Estate Institute",
  "Axon Education",
  "Axon - Pocket Prep Mock Exam",
  "Bossier Parish Community College",
  "Bossier Parish Community College- Math Department",
  "Brixey & Meyer",
  "CAIRP",
  "California State University, Monterey Bay",
  "Canadian Diabetes Educator Certification Board",
  "Capitus Real Estate Learning ",
  "Career Academy of Real Estate (AL)",
  "CASC",
  "CASE",
  "CDLE - Level One Test (Retake)",
  "CDLE L2A - Full Accreditation",
  "CDLE L2A -Orthopedics (incl. surgery)  - Extremities",
  "CDLE L2A Cardiology, Cardiovascular, Vascular, Pulmonary, Thoracic",
  "CDLE L2A Orthopedics (incl. surgery) - Upper Extremity",
  "CDLE L2A RETEST - Full Accreditation",
  "CDLE L2A RETEST - Gynecology",
  "CDLE L2A RETEST - Neurology, Neurological Surgery",
  "CDLE L2A RETEST - Ophthalmology, Otolaryngology",
  "CDLE L2A RETEST - Orthopedics (incl. surgery) - Extremities",
  "CDLE L2A RETEST - Plastic Surgery",
  "CDLE L2A RETEST -Allergy, Immunology, Toxicology, G. Surgery",
  "CDLE L2A RETEST -Urology",
  "CDLE L2A RETEST- Ortho (incl surgery) UEOrthoHandPlasticHand",
  "CDLE L2A RETEST-Dermatology",
  "CDLE L2A RETEST-Orthopedics (incl. surgery) - Spine",
  "CDLE L2A RETEST-Orthopedics, General, Rheumatology",
  "CDLE L2A RETEST-Orthopedics, Lower Extremity",
  "CDLE L2A RETEST-Plastic Surgery Limited to Hand and Neck",
  "CDLE L2A RETEST-Psychiatry, Psychiatry & Neurology",
  "CDLE L2A RETESTCardio Vascular Surgery,Pulmonary Thoracic",
  "CDLE L2A-Allergy, Immunology, Toxicology, General Surgery",
  "CDLE L2A-Dermatology",
  "CDLE L2A-Gynecology",
  "CDLE L2A-Neurology, Neurological Surgery",
  "CDLE L2A-Ophthalmology, Otolaryngology",
  "CDLE L2A-Orthopedics (incl. surgery)  - Lower Extremity",
  "CDLE L2A-Orthopedics (incl. surgery)  - Spine",
  "CDLE L2A-Orthopedics (incl. surgery) - General, Rheumatology",
  "CDLE L2A-Plastic Surgery",
  "CDLE L2A-Plastic Surgery Limited to Hand and Neck",
  "CDLE L2A-Psychiatry, Psychiatry & Neurology",
  "CDLE L2A-Urology",
  "CDLE Level One Test",
  "Cedarville University",
  "Church Mutual Commercial Lines Consultant",
  "Church Mutual Insurance Company, S.I.",
  "CMS - AAGL Pratical",
  "CMS - AAGL Written",
  "CMS - Alliance of Crop, Soil, and Environmental Science Societies (ACSESS)",
  "CMS - Certification Management Services",
  "CMS - Demos",
  "Colibri Real Estate ",
  "Colibri Real Estate - New Jersey",
  "Colibri Real Estate - Streaming (AL)",
  "Colibri Real Estate - Streaming (GA)",
  "Colibri Real Estate - Streaming (MD)",
  "Colibri Real Estate - Streaming (PA)",
  "Colibri Real Estate - Streaming (TX)",
  "Colibri Real Estate - Streaming (VA)",
  "Colibri Real Estate - Texas",
  "College of Chiropractors of Ontario (CCO)",
  "College of Patent Agents and Trademark Agents (CPATA)",
  "College of Registered Psychotherapists of Ontario (CRPO)",
  "College of Southern Idaho",
  "College of St. Mary",
  "Colorado Mesa University",
  "Cordial EMS",
  "Cordial EMS - Retake",
  "CPATA Practice Exam",
  "CSLA",
  "Cumbie and Trull",
  "CUNY School of Medicine",
  "Cyber Academy of South Carolina",
  "Des Moines University",
  "Des Moines University  (COMSAE)",
  "Des Moines University - College of Medicine",
  "Eastern Kentucky University",
  "Eastern Virginia Medical School",
  "Edmonds eLearning",
  "EPEC",
  "Erie Insurance",
  "FDMS - Florida Certified Contract Manager (FCCM)",
  "FDMS - Florida Certified Contract Negotiator (FCCN)",
  "FHEA",
  "FIU (Florida International University)",
  "FLSC/NCA",
  "Foundation for Oral Rehabilitation",
  "George Washington University",
  "Georgia State University",
  "HAAG Certification Exam",
  "Heron Academy of South Carolina (Virtual SC)",
  "Home Builders Association of Alabama (HBAA)",
  "Hondros College",
  "HRPA",
  "ICA - Texas",
  "Illinois Institute of Technology",
  "Inspection Certification Associates (ICA)",
  "Institute for the Advancement of FSP",
  "International QEEG Certification Board",
  "Jefferson College",
  "Jones College",
  "Kansas State Department of Education (KSDE)",
  "Kaplan",
  "Kennesaw State University",
  "Kentucky Real Estate ",
  "LA Unified School District (LAUSD)",
  "LaGrange College",
  "Language Learning & Testing Foundation",
  "Laurel Springs School",
  "Law Society of British Columbia",
  "Le Moyne College",
  "Learn+Earn",
  "Lehigh University",
  "Lexington School District One",
  "Los Angeles Community College District (LACCD)",
  "Louisiana Life Safety & Security Association",
  "LSU of Alexandria ",
  "Map Testing for Homeschoolers",
  "Maple Tree",
  "McKissock Appraisal (Streaming)",
  "McKissock Appraisal Online",
  "MSLP",
  "Midwestern University",
  "Midwestern University- Practice exam",
  "MindHacker Challenge",
  "Mississippi Security Association",
  "Mortgage Bankers Association (MBA)",
  "Mount Saint Joseph College",
  "MY Real Estate School (AL)",
  "NBC Dental",
  "NBC Dental Practical",
  "NCA - Failed Exams",
  "NCA Simulation Exam",
  "North Carolina School of Science and Mathematics",
  "North Eastern University",
  "Northwestern State University of Louisiana",
  "NRF Foundation RISE Up (Kaleidoscope)",
  "Nunez Community College",
  "OKBFAA",
  "Oxford Research Institute, Inc (ORI)",
  "PACE University",
  "PACE UNIVERSITY - PLEASANTVILLE",
  "Pace University Practice Exams",
  "Pace University- Lenox Hill Hospital PA Program",
  "Pace University- NY",
  "Patterson & Associates IA",
  "PDH Academy Real Estate",
  "Penn Foster",
  "Phoenix College",
  "Phoenix College A",
  "PICC Excellence",
  "Polley Associates School of Real Estate",
  "Professional Astrologers Alliance (PAA)",
  "QWEL -  University of Reno",
  "QWEL - CA WEP",
  "QWEL - CalWEP Spanish Mock Test",
  "QWEL - City of Aspen",
  "QWEL - City of Bozeman",
  "QWEL - Coachella Valley Water District",
  "QWEL - Energy Smart Academy (Santa Fe)",
  "QWEL - Regional Water Authority (RWA)",
  "QWEL - WSA San Diego",
  "Real Estate Institute",
  "Real Estate School of South Carolina",
  "RQIEC",
  "Rush University",
  "Saint Louis University PA Program",
  "Santa Fe College",
  "SC Virtual",
  "SCIC",
  "Siena Consulting",
  "South Carolina Real Estate School (PERRY)",
  "SPEEDRUN Contest",
  "St. Bonaventure University",
  "Stephens College",
  "Sumadi - Honduras University",
  "Superior School of Real Estate",
  "TEL Education Proctoring",
  "Tennessee Network of Security Integrators",
  "Texas Tech University",
  "Touro University",
  "UMass",
  "University of Dubuque",
  "University of Louisiana Lafayette",
  "University of North Carolina",
  "University of South Carolina",
  "US Department of Agriculture",
  "UTSouthwestern",
  "Van Dyk RS",
  "WebCE",
  "Western New England University (WNE)",
  "Wilson County Emergency Services",
  "Zaxbys",
];

export default function BackupQueue() {
  const [pageState, setPageState] = useState("form"); // form, waiting

  // check if session id is in session storage, setPageState to waiting
  useEffect(() => {
    if (sessionStorage.getItem("backupSessionId")) {
      setPageState("waiting");
    }
  }, []);

  if (pageState === "form") {
    return <QueueForm setPageState={setPageState} />;
  }

  if (pageState === "waiting") {
    return <QueueWaiting />;
  }
}

function QueueForm({ setPageState }) {
  const [options, setOptions] = useState(clientOptions);
  const [searchParams, setSearchParams] = useSearchParams();
  const org = searchParams.get("org");
  async function handleSubmit(event) {
    // create record in database
    // on return save id to session storage AND above state
    // setPageState("waiting");
    event.preventDefault();
    console.log(event.value);

    try {
      const data = await API.graphql({
        query: CreateBackupQueue,
        variables: {
          input: {
            client: org ? org : event.value.organization,
            exam: event.value.exam,
            candidate_name: event.value.name,
            status: "queue",
            type: "BackupQueue",
          },
        },
        authMode: "API_KEY",
      });
      console.log(data);

      // set to session storage
      sessionStorage.setItem("backupSessionId", data.data.createBackupQueue.id);
      setPageState("waiting");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Box align="center" pad="large" justify="center">
      <Card align="center" pad="small" border>
        <CardHeader direction="column">
          <Heading textAlign="center" level="1">
            Proctor Waiting Room
          </Heading>
          <Text textAlign="center">
            Fill out the form below to get connected to a proctor
          </Text>
          <Box border="bottom" />
        </CardHeader>
        <CardBody>
          <Form onSubmit={(event) => handleSubmit(event)}>
            {org ? (
              <Box align="center" pad="small" justify="center">
                <Text size="large" weight="bold">
                  {org}
                </Text>
              </Box>
            ) : (
              <FormField
                name="organization"
                htmlFor="organization-input_input"
                border
                round="small"
                required
              >
                <Text margin="small">Select your organization</Text>
                <Select
                  options={options}
                  name="organization"
                  onSearch={(text) => {
                    const escapedText = text.replace(
                      /[-\\^$*+?.()|[\]{}]/g,
                      "\\$&"
                    );
                    const exp = new RegExp(escapedText, "i");
                    setOptions(
                      clientOptions.filter((option) => exp.test(option))
                    );
                  }}
                  searchPlaceholder="Search..."
                  a11yTitle="Select your organization"
                  id="organization-input"
                  autoFocus={true}
                />
              </FormField>
            )}

            <FormField
              name="exam"
              htmlFor="exam-input_input"
              border
              round="small"
              required
            >
              <Text margin="small">Enter your exam name</Text>
              <TextInput
                name="exam"
                id="exam-input"
                a11yTitle="Enter your exam name"
              />
            </FormField>
            <FormField
              name="name"
              htmlFor="name-input_input"
              border
              round="small"
              required
            >
              <Text margin="small">Enter your first and last name</Text>
              <TextInput
                name="name"
                id="name-input"
                a11yTitle="Enter your first and last name"
              />
            </FormField>
            <Button type="submit" primary label="Submit" color="black" />
          </Form>
        </CardBody>
      </Card>
    </Box>
  );
}

function QueueWaiting() {
  const [currentSession, setCurrentSession] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [showJoinButton, setShowJoinButton] = useState(false);
  const [meetingLink, setMeetingLink] = useState(null);
  useEffect(() => {
    const ses = sessionStorage.getItem("backupSessionId");
    setSessionId(ses);
    // get current session status
    // if status is "proctoring" redirect to proctor page
    // if status is "queue" keep waiting
    getBackupQueue();

    const updateSub = API.graphql({
      query: onUpdateBackupQueue,
      variables: {
        filter: {
          id: { eq: ses },
        },
      },
      authMode: "API_KEY",
    }).subscribe({
      next: (data) => {
        console.log(data);
        if (data.value.data.onUpdateBackupQueue.meeting_link) {
          setMeetingLink(data.value.data.onUpdateBackupQueue.meeting_link);
          // wait for 10 seconds before showing the join button
          setTimeout(() => {
            setShowJoinButton(true);
          }, 10000);
        }
      },
      error: (error) => {
        console.log(error);
      },
    });

    return () => {
      updateSub.unsubscribe();
    };
  }, []);

  async function getBackupQueue() {
    const sesId = sessionStorage.getItem("backupSessionId");
    try {
      const data = await API.graphql({
        query: `query GetBackupQueue($id: ID!) {
                    getBackupQueue(id: $id) {
                        id
                        client
                        exam
                        candidate_name
                        status
                        meeting_link
                    }
                }
                `,
        variables: {
          id: sesId,
        },
        authMode: "API_KEY",
      });
      console.log(data);
      setCurrentSession(data.data.getBackupQueue);
      if (data.data.getBackupQueue.meeting_link) {
        setMeetingLink(data.data.getBackupQueue.meeting_link);
        // wait for 10 seconds before showing the join button
        setTimeout(() => {
          setShowJoinButton(true);
        }, 10000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Box align="center" pad="large" justify="center" height="medium">
      <Heading level="1">Proctor Waiting Room</Heading>
      {showJoinButton ? (
        <Box align="center" pad="large" justify="center">
          <Text>You can now connect with a proctor!</Text>
          <Text>Click the button below to join the Google Meeting</Text>
          <Button
            primary
            label="Join Meeting"
            color="black"
            onClick={() => (window.location.href = meetingLink)}
          />
        </Box>
      ) : (
        <Box align="center" pad="large" justify="center">
          <Spinner size="large" />

          <Text>Waiting for a proctor to connect...</Text>
          <Text>
            You are currently in the waiting room. Please wait for a proctor to
            connect you. Do not close this tab, as you will lose your place in
            line.
          </Text>

          <Text>
            It is okay to refresh this page if you have been waiting for a long
            time. Wait at least 1 minute between refreshes.
          </Text>
        </Box>
      )}
    </Box>
  );
}
